import React from 'react'
import './App.css';
  
{/*3*/}
const HorizontalLine = ({ width, color }) => {
    const lineStyle = {
      width: width || '100%', 
      height: '1px',      
      backgroundColor: color || 'white', 
      margin: '10px 0',      
    };
  
    return <div style={lineStyle}></div>;
  };

function MenuSpecial() {
  return (

     <div className="origin">
       <title>Menu spécial</title>
       <meta name="description" content="Menu spécial"/>
    <div className="gallery-wrapper">
    
  
    <div className="gallery" style={{justifyContent:'center', display:"flex", color:"white", fontFamily:"-moz-initial", fontSize:20, flexDirection:"column", alignItems:"center", width:"100%"}}>
  
{/*<div style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/NoelBackground.jpg)`,
    height: '100vh', 
    width: '100%',    
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
    alignItems: 'center', 
    overflowX: 'hidden'
    }}>
        <title>Menu Saint-Valentin</title>
        <meta name="description" content="Menu de Saint-Valentin"/>

<div style={{
      flex: 1, // Prend tout l'espace restant disponible
      overflowY: 'auto', // Permet le défilement vertical
      padding: '20px', // Espacement interne (optionnel)
      width:"100%",
      alignItems:"center",
      justifyContent: 'center', 
      display:"flex"
      
     
    }}>
        <title>Menu Saint-Valentin</title>
    <div className="gallery" style={{justifyContent:'center', display:"flex", color:"black", fontFamily:"-moz-initial", fontSize:20, flexDirection:"column", alignItems:"center", width:"100%"}}>*/}
   
     


     {/* MENU */}
     <p style={{ fontFamily:'fantasy',fontSize:28, textAlign:"center"}}>Menu de Pâques
{/* <br /><span style = {{fontSize:22}}>Servi du 12/02 au 16/02</span>*/}
        <br/><span style = {{fontSize:20}}>4 services 62€</span></p>
     
     <div style={{marginTop:-20, fontFamily:"-moz-initial", fontSize:20, alignItems:'center', justifyContent:"center", display:'flex', flexDirection:"column", maxWidth:"80%", textAlign:"center"}}>
     <p>Mises en bouche</p>
     <HorizontalLine width="20%"  />
     <p>Oeuf parfait 62°/asperges blanches/crevettes grises</p>

     <HorizontalLine width="20%"  />
     <p>Ris de veau poêlés/ail des ours/morilles</p>
      {/*
     <HorizontalLine width="20%"  />

     <p>Foie gras d'oie poêlé aux raisins</p>
     
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Dos de cabillaud, pommes purée de brocolis beurre aux tomates cerises</p>*/}

     <HorizontalLine width="20%"  />
     <p>Agneau en deux façon/carré en croûte d'herbes/ gigot cuit 48h</p>
     <p style={{marginTop:-10, marginBottom:-10}}>OU</p>
     <p>Filet de maigre/risotto aux petits pois</p>

     <HorizontalLine width="20%"  />
     <p>meringue fraises/ glace vanille</p>

     </div>




    </div>
    </div>
    </div>

  )
}

export default MenuSpecial
